import React, { useState, useEffect } from "react";
import "./style.css";
import GenialSkillsLogo from "../../../assets/images/Logo-SJ-estudia-avanza.svg";
import GSApp from "../../../assets/images/logo-GS-APP.png";
import { useTranslation } from "react-i18next";
import { ENVIROMENT } from "../../../config/environment";
import { FaQuestionCircle, FaGlobe } from "react-icons/all";

const languages = [
  { id: "es", name: "Español" },
  { id: "en", name: "English" },
];

const HeaderLogin = () => {
  const { t, i18n } = useTranslation(["login"]);
  const language = i18n.language.split("-")[0];
  const [languageSelected, setLanguageSelected] = useState(language);

  useEffect(() => {
    i18n.changeLanguage(language);
    setLanguageSelected(language);
  }, []);

  function onChangeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    setLanguageSelected(e.target.value);
  }

  return (
    <>
      <header className="main-header--dashboard">
        <div className="main-header_GenialSkills-logo--dashboard">
          <img src={GenialSkillsLogo} alt="" />
          {
            ENVIROMENT == "development" &&
            <span className="noticesDev">
              Dev
            </span>
          }
          {
            ENVIROMENT == "beta" &&
            <span className="noticesBeta">
              Beta
            </span>
          }
        </div>
      </header>
    </>
  );
};

export default HeaderLogin;
